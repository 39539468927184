import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Components/Common/Style';
import DotLottiePlayer from './Components/Common/DotLottiePlayer';
import { toggleAttr } from './utils/functions';

document.addEventListener('DOMContentLoaded', () => {
	const allPlayers = document.querySelectorAll('.wp-block-lpb-lottie-player');
	allPlayers.forEach(playerEl => {
		const attributes = JSON.parse(playerEl.dataset.attributes);

		createRoot(playerEl).render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<RenderLottiePlayer attributes={attributes} clientId={attributes.cId} />
		</>);

		playerEl?.removeAttribute('data-attributes');
	});
});

const RenderLottiePlayer = ({ attributes, clientId }) => {
	const { isControls, isAutoplay, isLoop, isHover, link, isLinkInNewTab, interactivity, interactivityMode, interactivityContainer, interactivityActions, isCap, caption } = attributes;

	const lottieEl = useRef(null);

	useEffect(() => {
		const lottiePlayer = document.querySelector(`#lpbLottiePlayer-${clientId} .lpbLottiePlayer lottie-player`);

		toggleAttr(lottiePlayer, isControls, 'controls', '');
		toggleAttr(lottiePlayer, isAutoplay, 'autoplay', '');
		toggleAttr(lottiePlayer, isLoop, 'loop', '');
		toggleAttr(lottiePlayer, isHover, 'hover', '');
	}, []);

	// Interactivity
	useEffect(() => {
		const player = lottieEl?.current;

		if (player && interactivity && 'noInteractivity' !== interactivity) {
			player.addEventListener('ready', () => {
				LottieInteractivity.create({
					player: player.getLottie(),
					container: 'scrollRelativeToContainer' === interactivity ? interactivityContainer : `#lpbLottiePlayer-${clientId}`,
					mode: interactivityMode,
					actions: interactivityActions
				});
			});
		}
	}, [lottieEl?.current]);

	return <div className='lpbLottiePlayer'>
		{link ? <a href={link} target={isLinkInNewTab ? '_blank' : '_self'} rel='noreferrer'><DotLottiePlayer ref={lottieEl} attributes={attributes} /></a> : <DotLottiePlayer ref={lottieEl} attributes={attributes} />}

		{isCap && <><br /><span className='caption'>{caption}</span></>}
	</div>
}